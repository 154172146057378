import { inject, Injectable, signal } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Platform } from '@ionic/angular/standalone';
import { ONE_ON_ONE_ADMIN_MENUS } from '@pixels/client/pages/admin/admin.model';
import { AppInfo } from '@pixels/client/pixels/app-info';
import { AppKeyAgain } from '@pixels/universal/model/apps/app-types';
import { AgainProjectMeta } from '@pixels/universal/model/apps/project-meta/again-project-meta';

@Injectable({ providedIn: 'root' })
export class AgainAppInfo extends AppInfo {
  public readonly appType = AppKeyAgain;
  public readonly serviceName = _('@어게인@');
  public readonly appStoreUrl = signal(AgainProjectMeta.appStoreUrl ?? '');
  public readonly playStoreUrl = signal(`https://play.google.com/store/apps/details?id=${AgainProjectMeta.appId}`);

  public override readonly admobBannerVideoChatAppId = (inject(Platform).is('ios') ? AgainProjectMeta.ios?.admobBannerVideoChatAppId : AgainProjectMeta.android?.admobBannerVideoChatAppId) ?? '';
  public override readonly loginTitle = _('@어게인@');
  public override readonly loginDescription = _('@돌아온_싱글_친구를_만들어보아요@');
  public override readonly loginAppIconUrl = 'https://cdn.pixelteam.io/pixels/app-icons/again-app-icon.svg';
  public override readonly bannerPlaceHolder = _('@어게인_돌싱_재혼_친구_만들기_소개팅@');
  public override readonly adminMenus = ONE_ON_ONE_ADMIN_MENUS;
}
