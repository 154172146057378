import { AppKeyAgain } from '@pixels/universal/model/apps/app-types';
import { ProjectMeta } from '@pixels/universal/model/apps/project-meta.model';
import { Phase } from '@pixels/universal/model/phase-model';

const FOLDER_NAME = 'again-client';
const PROD_WEB_PUSH_KEY = 'BBPH5fwGtt4x0gtaa5cs4wy4xP4HwsdneeNW4Jdp66PxyI_97IoXuaidUrr7vxRnnI_zohxRnOAr00GHivlgF2g';
const DEV_WEB_PUSH_KEY = 'BL-A8s7Cz-RUWYmvR6W_8nNw5s4EMFtTIHiwOTHW7VJgRreRf0ZFLInORUfN_fyEAEy-Xpz-pk73eaTElpPLk0I';
const PROD_AUTH_CALLBACK_URL = 'https://pixels-again.firebaseapp.com/__/auth/handler';
const DEV_AUTH_CALLBACK_URL = 'https://pixels-again-dev.firebaseapp.com/__/auth/handler';
const ProdFirebaseConfig = {
  apiKey: 'AIzaSyA3PXfH-aLR1xVqMJLFEWFcPsVItEjmY7U',
  authDomain: 'pixels-again.firebaseapp.com',
  projectId: 'pixels-again',
  storageBucket: 'pixels-again.appspot.com',
  messagingSenderId: '854590318596',
  appId: '1:854590318596:web:79e227e74201221d57ed51',
  measurementId: 'G-X2735PE5ZH'
};
const DevFirebaseConfig = {
  apiKey: 'AIzaSyCpNnBGYVeQmSuFBiKLd2pc_Ti7myNKET0',
  authDomain: 'pixels-again-dev.firebaseapp.com',
  projectId: 'pixels-again-dev',
  storageBucket: 'pixels-again-dev.appspot.com',
  messagingSenderId: '213758924700',
  appId: '1:213758924700:web:ff0a68981de16f5b855a59',
  measurementId: 'G-TC6444EZ6H'
};
const PROD_TALK_PLUS_APP_ID = '11270666-8a4f-45bc-9375-abeb78d929a1';
const DEV_TALK_PLUS_APP_ID = '618a8b13-f9f9-460e-abea-aaf7728569e4';

export const AgainProjectMeta: ProjectMeta = {
  id: AppKeyAgain,
  mainPrimaryColor: '#C956FF',
  folderName: FOLDER_NAME,
  publicPath: `dist/apps/${FOLDER_NAME}/browser`,
  supportPhases: [Phase.prod, Phase.dev],
  port: 8113,
  apiName: 'again-api',
  apiPort: 5013,
  appId: 'io.pixelteam.again',
  appStoreUrl: 'https://apps.apple.com/app/id6499508345',
  appName: '어게인',
  appNameEn: 'Again',
  appSchemeName: 'Again',
  testTargetUrl: 'https://test-again.pixelteam.io',
  devTargetUrl: 'https://dev-again.pixelteam.io',
  prodApi: 'https://again-api.pixelteam.io',
  devApi: 'https://dev-again-api.pixelteam.io',
  firebaseId: 'pixels-again',
  iosAppId: '1:198703238152:ios:0e61264462275a444815bd',
  androidAppId: '1:198703238152:android:82ae8ea509379a464815bd',
  testHostname: 'test-again',
  clientSentryDsn: 'https://0043587b0c46a178d8e9b28bec2a999e@o190729.ingest.us.sentry.io/4507164909699072',
  prod: {
    deployPath: '/api/v1/project/9070/stage/10103/scenario/11293/deploy',
    targets: ['prod-03'],
    port: 8400,
    androidAppId: '1:854590318596:android:7ca7187e18b0765e57ed51',
    iosAppId: '1:854590318596:ios:1c160a50d593a5c957ed51',
    iosEncodingAppId: 'app-1-854590318596-ios-1c160a50d593a5c957ed51',
    webAppId: '1:854590318596:web:79e227e74201221d57ed51',
    kakaoAppKey: '50e67a7612ae02945ae8d0caf89614c5',
    googleReversedId: 'com.googleusercontent.apps.854590318596-0crivdcl5a7g9u2km03ju8teu5aulq86',
    webPushKey: PROD_WEB_PUSH_KEY,
    firebaseConfig: ProdFirebaseConfig,
    talkPlusAppId: PROD_TALK_PLUS_APP_ID,
    apiUrl: 'https://again-api.pixelteam.io',
  },
  dev: {
    deployPath: '/api/v1/project/9070/stage/10104/scenario/11280/deploy',
    targets: ['dev-00'],
    port: 8401,
    androidAppId: '1:213758924700:android:575ae82f7e40b411855a59',
    iosAppId: '1:213758924700:ios:484583e9fb9f0975855a59',
    iosEncodingAppId: 'app-1-213758924700-ios-484583e9fb9f0975855a59',
    webAppId: '1:213758924700:web:ff0a68981de16f5b855a59',
    kakaoAppKey: 'b09f3f0927a59ee34ccb73e910e8c25b',
    googleReversedId: 'com.googleusercontent.apps.213758924700-vgfq71v93a738e0396lccgn7tcou48er',
    webPushKey: DEV_WEB_PUSH_KEY,
    firebaseConfig: DevFirebaseConfig,
    talkPlusAppId: DEV_TALK_PLUS_APP_ID,
    apiUrl: 'https://dev-again-api.pixelteam.io',
  },
  test: {
    androidAppId: '1:213758924700:android:cc0eea9d84b35448855a59',
    iosAppId: '1:213758924700:ios:c2f5f437481b7f74855a59',
    iosEncodingAppId: 'app-1-213758924700-ios-c2f5f437481b7f74855a59',
    kakaoAppKey: 'b09f3f0927a59ee34ccb73e910e8c25b',
    googleReversedId: 'com.googleusercontent.apps.213758924700-jnjpekc5o9n80lcgefldrg41jifsmpfg',
    webPushKey: DEV_WEB_PUSH_KEY,
    firebaseConfig: DevFirebaseConfig,
    talkPlusAppId: DEV_TALK_PLUS_APP_ID,
    apiUrl: 'https://test-again-api.pixelteam.io',
  },
  android: {
    admobAppId: 'ca-app-pub-4000507444081320~9741911342',
    admobBannerVideoChatAppId: 'ca-app-pub-4000507444081320/3657850731',
  },
  ios: {},
  NSCameraUsageDescription: '카메라로 찍은 사진을 메시지에 첨부 또는 프로필 사진으로 등록할 수 있습니다.',
  NSPhotoLibraryAddUsageDescription: '회원님이 어게인에 사진을 저장에 대한 액세스 권한을 사용합니다.',
  NSPhotoLibraryUsageDescription: '앨범에 있는 사진과 GIF를 메시지에 첨부 또는 프로필 사진으로 등록할 수 있습니다.',
  NSCameraUsageDescriptionEn: 'You can attach a photo taken with your camera to a message or list it as your profile picture.',
  NSPhotoLibraryAddUsageDescriptionEn: 'You have access to save photos to your account.',
  NSPhotoLibraryUsageDescriptionEn: 'You can use photos and GIFs from your albums as attachments to messages or as your profile picture.',
  consoleLogo: `
 █████╗  ██████╗  █████╗ ██╗███╗   ██╗
██╔══██╗██╔════╝ ██╔══██╗██║████╗  ██║
███████║██║  ███╗███████║██║██╔██╗ ██║
██╔══██║██║   ██║██╔══██║██║██║╚██╗██║
██║  ██║╚██████╔╝██║  ██║██║██║ ╚████║
╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚═╝╚═╝  ╚═══╝`
};

